import { ApolloLink } from '@apollo/client'
import { LoginToken } from 'app/Services/Auth/types'
import jwtDecode from 'jwt-decode'

export const auth = new ApolloLink((operation, forward) => {
  let token = localStorage.getItem('platform:apollo:token')
  // Identificamos el token anónimo en caso de estar en las rutas de test de nivel
  const tokenLvlTest = localStorage.getItem('token_test')
  if (tokenLvlTest) {
    token = tokenLvlTest
  }

  // Buscamos el token por la url en caso de venir desde fuera.
  const { pathname, search } = window.location
  if (pathname.includes('/test-level/')) {
    const tokenTemp = search.replace('?token=', '')
    try {
      const tokenDecoded: LoginToken = jwtDecode(tokenTemp)
      const currentTime = new Date().getTime() / 1000
      if (currentTime < tokenDecoded.exp) {
        token = tokenTemp
      }
    } catch (err) {
      // ...
    }
  }

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'app-referer': window.location.href,
    },
  }))
  return forward(operation)
})
