import { useEffect, useState } from 'react'

import { useNewDate } from 'app/Services/useNewDate'

export const useGetDate = () => {
  const { getDate } = useNewDate()
  const [now, setNow] = useState<Date>(new Date())

  useEffect(() => {
    getDate((newDate) => {
      setNow(newDate)
    })
  }, [])

  return { now }
}
