import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'
import { RequestStatus } from '@eclass/api'

type ApiResolve = {
  data: any
  status: RequestStatus
}
const getAllInstitutions = (institutionTypeId: number, countryId: number) => {
  const requestId = 'getAllInstitutions'
  const search = async (dispatch) => {
    getToken()
    const query = `
    query allInstitutions {
      allInstitutions(
        paginate: 1000
        filter: {
          institutionTypeId: ${institutionTypeId},
          countryId: ${countryId}
        }
        orderBy: name_ASC
      )
      {
        id
        name
      }
    }
    `

    return axios
      .post(`${apiURL}/graphql`, { query })
      .then((response) => {
        const { allInstitutions } = response.data.data
        if (response.status === 200 && allInstitutions) {
          const ret: ApiResolve = {
            data: allInstitutions.filter(({ name }) => name.length > 0),
            status: {
              success: true,
              name: 'Petición Exitosa',
              code: 200,
              detail: `Se encontraron ${allInstitutions.length} instutuciones`,
            },
          }
          return ret
        } else {
          const none: ApiResolve = {
            data: [],
            status: {
              code: 400,
              name: 'Error al listar Instituciones',
              success: false,
              detail: 'Error al buscar las instituciones',
            },
          }
          return none
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
  return search
}

export default getAllInstitutions
