import { useDateFormat } from 'app/Services'
import { filterEventsNoInitCourse } from 'app/Views/Calendar/services/useFormatEventsForCalendar'

export const useFilterFormatedEvents = (now) => {
  const { formatDateTZ } = useEventsTimeZone()
  const { dateFormat } = useDateFormat()

  const formatEvents = (arrayEvents: any[]) => {
    const hoal = formatDateTZ(now)
    const neww = filterEventsNoInitCourse(arrayEvents, hoal)
    return neww.map((event) => {
      return {
        ...event,
        start: event.start,
        end: event.end,
        formatedDate: {
          start: dateFormat({
            date: event.start,
            format: { free: 'EEE d MMM' },
          }),
          hours: dateFormat({
            date: event.start,
            format: 'h',
          }),
        },
      }
    })
  }

  return { formatEvents }
}

export const useEventsTimeZone = () => {
  const { dateFormat } = useDateFormat()

  const formatDateTZ = (date: Date) => {
    return dateFormat({
      date: date,
      format: { free: `u-MM-dd'T'HH:mm:ss'.000Z'` },
    })
  }

  return { formatDateTZ }
}
