import { useState, useCallback, useEffect } from 'react'

import { Types } from '../types'

const MAX_AVATAR = 3

/**
 * Hooks que retorna lista de avatar para mostrar,
 * con un límite de tres como máximo sin repetición.
 * @example
 * const { listAvatars } = useAvartars(answers)
 */
export const useAvatars = (answers: Types.Comment[]) => {
  const [state, setState] = useState<Types.DataUser[]>([])
  const [statusList, setStatusList] = useState('initial')

  const handleAnswers = useCallback(() => {
    setStatusList('filtrando')
    const initialData = answers.map((element: Types.Comment) => {
      if (element.User) {
        return { ...element.User.Person }
      } else if (element.Student) {
        return { ...element.Student.Person }
      } else {
        return {
          id: null,
          fullName: null,
          picture: null,
        }
      }
    })

    const newState: Types.DataUser[] = []
    initialData.forEach((element: Types.DataUser) => {
      const coincidence = newState.find((item: Types.DataUser) => item.id === element.id)
      if (coincidence === undefined && newState.length < MAX_AVATAR) {
        newState.push(element)
      }
    })
    setState(newState)
  }, [answers])

  useEffect(() => {
    if (state.length === 0 && statusList === 'initial') {
      handleAnswers()
    }
  }, [state, handleAnswers, statusList])

  return { listAvatars: state }
}
