import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MouseEvent } from 'react'

import { HeaderAvatar } from '../HeaderAvatar'
import { useSetDate } from './setDate'
import { FileList, useAlerts } from '../'

import * as Style from './style'

import { Store } from 'app/Redux/types'
import { Props } from '../types'

import { BtnTertiary, TinyAlert } from '@eclass/ui-kit'
import { LayoutImageZoom } from './LayoutImageZoom'

/**
 * Componente que muestra el comentario realizado por un usuario,
 * con información como avatar del usuario, fecha de creación del comentario,
 * y acciones asociadas a este (respondes y editar si tu hiciste el comentario),
 * como tambien casos especiales (pregunta sugerida, pregunta nueva)
 * @example
 * <Content
 *   comment={ReactHtmlParser(comment.text)}
 *   date={new Date()}
 *   edit={edit}
 *   helper={false}
 *   respond={respond}
 *   user={user}
 *  />
 */
export const Content = ({
  comment,
  date,
  edit,
  profileId,
  respond,
  suggestedAnswer,
  user,
  files,
  id,
  isNew,
}: Props.Content) => {
  const { t } = useTranslation()
  const {
    loggedUser,
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Store) => state)
  const { modalInit } = useAlerts()

  const helper = profileId === 3
  const teacher = profileId === 2
  const coordinator = profileId === 6

  const helperMode = helper || suggestedAnswer || teacher || coordinator
  const isHelper = helperMode ? 'isHelper' : ''

  const handleLink = (event: MouseEvent<HTMLDivElement>) => {
    const e = event.target as Element
    if (e.tagName === 'A') {
      modalInit({
        event,
        type: 'redirect',
      })
    }
  }

  return (
    <Style.Article
      role="Comment"
      id={`comment-${id}`}
      className={`${isHelper} ${suggestedAnswer ? 'suggestedAnswer' : ''}`}
    >
      <div className={`header ${isHelper}`} data-testid="CommentHeader">
        <HeaderAvatar
          user={user}
          profileId={profileId}
          suggestedAnswer={suggestedAnswer}
          size={36}
        />
      </div>

      <Style.Comment role="CommentText" onClick={handleLink}>
        {isNew && <TinyAlert status="new" text={t('QuestionNewAnswer')} margin="0 0 4px" />}
        <LayoutImageZoom>{comment}</LayoutImageZoom>
        <FileList files={files || []} download />
      </Style.Comment>
      <Style.Footer>
        <span>{useSetDate(date)}</span>
        {!suggestedAnswer && courseInfo.Student?.dates?.canGenerateActivity! && (
          <Style.Actions>
            {loggedUser.user?.id === user.id && (
              <BtnTertiary iconStatus="edit" withoutColor onClick={() => edit && edit()}>
                {t('ResourceEdit')}
              </BtnTertiary>
            )}

            <BtnTertiary iconStatus="answer" withoutColor onClick={() => respond && respond()}>
              {t('ResourceAnswer')}
            </BtnTertiary>
          </Style.Actions>
        )}
      </Style.Footer>
    </Style.Article>
  )
}
