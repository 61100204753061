import { CalendarReducerAction, CalendarReducer } from './types'

import { InitialState } from './initialState'

export const calendar = (
  state: CalendarReducer = InitialState,
  action: CalendarReducerAction
): CalendarReducer => {
  switch (action.type) {
    case 'CALENDAR_ADD_COURSE_COLORS':
      return {
        ...state,
        ...dispatchCourseColors(state, action.payload),
      }
    default:
      return state
  }
}

export const colorsCalendar = [
  '#832DA4',
  '#00857A',
  '#C63361',
  '#5383EC',
  '#DF742C',
  '#A1635C',
  '#5C1179',
  '#AE1042',
  '#3C4DE4',
  '#C36220',
  '#82504A',
]

const dispatchCourseColors = (state: CalendarReducer, payload: any): CalendarReducer => {
  const courseColors = { ...state.courseColors }

  // Encuentra el índice del último color usado
  let lastUsedIndex = Object.values(courseColors).reduce((maxIndex, color) => {
    const colorIndex = colorsCalendar.indexOf(color)
    return colorIndex > maxIndex ? colorIndex : maxIndex
  }, -1)

  // Obtener cursos únicos y ordenarlos alfabéticamente por nombre
  const uniqueCourses = Array.from(
    new Map(payload.map((event) => [event.course.id, event.course])).values()
  ) as { id: number; name: string }[] // <- Aquí se especifica el tipo

  uniqueCourses.sort((a, b) => a.name.localeCompare(b.name))

  uniqueCourses.forEach(({ id }) => {
    const courseId = String(id)
    if (!courseColors[courseId]) {
      lastUsedIndex = (lastUsedIndex + 1) % colorsCalendar.length
      courseColors[courseId] = colorsCalendar[lastUsedIndex]
    }
  })

  return {
    courseColors,
  }
}
