import { useState, useEffect } from 'react'

import { useRequests } from 'app/Views/Calendar/services/useRequests'
import { useFilterFormatedEvents } from './useFormatedEvents'

export const useCalendarEvents = (now: string) => {
  const { getEvents } = useRequests()
  const { formatEvents } = useFilterFormatedEvents(now)
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState([])

  const fetchEvents = async () => {
    setLoading(true)
    try {
      const from = now.split('T')[0]
      const response: any = await getEvents({ from })
      const formattedEvents: any = formatEvents(response)
      setEvents(formattedEvents)
    } catch (error) {
      console.error('Error al cargar eventos:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return { events, loading }
}
