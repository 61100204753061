import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BtnPrimary, TinyAlert } from '@eclass/ui-kit'

import { HeaderAvatar } from '../HeaderAvatar'
import { filterString } from 'app/Components/UI'
import { useModal } from './useModal'
import { TextLink as StyleTextLink } from '../style'
import * as Style from './style'
import { Props } from '../types'

/**
 * Componente Wrapper de un editor
 *
 * Agregar un headerAvatar
 * y una botonera adicional al children que se le pasa
 *
 * @example
 * <CommentSystem.Editor user={user} editor={editorConfig}>
 *   <TextEditor />
 * </CommentSystem.Editor>
 */
export const Editor = ({ children, editor, user, edit = false, draft }: Props.Editor) => {
  const { t } = useTranslation()
  const refsWrapper = useRef<HTMLDivElement>(null)
  const { openModal } = useModal(refsWrapper.current)

  /**
   * Cambia el estado del editor, si tiene un borrador tiene un borde de color, sino un gris por defecto.
   */
  const [isDraft, setIsDraft] = useState(false)

  const handleCancel = () => {
    if (!editor.loading) {
      editor.cancel()
    }
  }

  useEffect(() => {
    if (draft !== undefined) {
      if (filterString(draft?.text!) > 0 && draft?.ResourceCommentAttacheds!.length !== 0) {
        setIsDraft(true)
      } else {
        setIsDraft(false)
      }
    } else {
      setIsDraft(false)
    }
  }, [draft])

  const onCancel = () => {
    if (!editor.loading) {
      handleCancel()
    }
  }

  return (
    <Style.Section role="Editor" edit={edit} isDraft={isDraft} words={editor.words}>
      {user && (
        <header>
          <HeaderAvatar user={user} size={36} />
        </header>
      )}
      <Style.Editor ref={refsWrapper} onClick={() => openModal(refsWrapper.current)}>
        {children}
      </Style.Editor>
      <Style.Footer className={`editorActions ${!editor.minCharacters ? 'notMinCharacters' : ''}`}>
        {editor.minCharacters !== undefined && (
          <TinyAlert
            status="info"
            text={t('QuestionMinimumCharacters', {
              value: editor.minCharacters,
            })}
          />
        )}

        <div className="editorActions-buttons">
          {editor.showCancel && !editor.loading && (
            <StyleTextLink data-testid="EditorCancel" onClick={onCancel}>
              {t('ContentCanceled')}
            </StyleTextLink>
          )}
          {user && (
            <BtnPrimary
              data-testid="editor-button"
              onClick={editor.save}
              disabled={editor.loading || !editor.words || editor.disabledSubmit}
              isLoading={editor.loading}
            >
              {editor.loading
                ? t('ResourceOpinionSending')
                : edit
                ? t('ResourceUpdate')
                : editor.buttonText
                ? editor.buttonText
                : t('CommentSystemReply')}
            </BtnPrimary>
          )}
        </div>
      </Style.Footer>
    </Style.Section>
  )
}
