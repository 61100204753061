import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NewTooltip, UserWay, CalendarDropdown } from '@eclass/ui-kit'
import { Box } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { NewDropdown } from 'app/Components/UI'
import { LogoEclass } from 'app/Components/UI/Icons'
import { useLogic } from './useLogic'
import { Wrapper } from './Style'
import { HeaderProps } from './types'
import { Container } from 'app/Layouts'
import { useGetDate } from 'app/Views/Calendar/services/useGetDate'
import { useCalendarEvents } from '../Calendar/Dropdown/useCalendarEvents'
import { Store } from 'app/Redux/types'
import { DropdownContainer } from './DropdownContainer'

/**
 * Componente cabecera de toda la aplicación.
 *
 * @example <Header config={config} />
 */
export const Header = ({ config }: HeaderProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { loading, logged, user, hasPermissions } = useLogic()
  const { t } = useTranslation()
  const { now } = useGetDate()
  const history = useHistory()
  const {
    calendar: { courseColors },
  } = useSelector((state: Store) => state)

  const date = new Date(now)
  const isoDate = date.toISOString()
  const { events, loading: loadingEvents } = useCalendarEvents(isoDate)

  const calendarDropdownText = {
    empty: t('CalendarDontEvent'),
    header: t('CalendarNextDates'),
    tooltip: t('Calendar'),
    events: {
      today: t('CalendarToday'),
      tomorrow: t('CalendarTomorrow'),
      next: t('CalendarNext'),
    },
    loading: t('CalendarLoading'),
    course: t('CalendarCourse'),
    buttonCalendar: t('CalendarGoto'),
  }

  useEffect(() => {
    if (isMenuOpen) {
      setMenuOpen(true)
    }
  }, [isMenuOpen])

  if (loading) {
    return null
  }

  const displayDropdown = (open: boolean) => {
    setIsOpen(open)
  }

  const redirecToCalendar = () => {
    history.push('/calendar')
  }

  return (
    <Wrapper className="Header" id="MainHeader" data-testid="MainHeader">
      <Container size="xl">
        <div className="Header__topbar flex">
          <div className="flex">{hasPermissions && <LogoLink config={config} />}</div>
          <div className="buttonsContainer">
            <NewTooltip
              label="Accesibilidad"
              isDisabled={isOpen}
              m="0 32px 0 0"
              className="tooltip"
            >
              <div className="userWay">
                <UserWay />
              </div>
            </NewTooltip>
            {logged && hasPermissions && (
              <Box sx={{ 'button.btn-secondary': { mr: 0 } }}>
                <NewTooltip label={t('CrossHelpCenterTooltip')} isDisabled={isOpen}>
                  <NewDropdown
                    displayDropdown={displayDropdown}
                    headerTitle={t('CrossHelp')}
                    dropdownType="helpCenter"
                    btnIcon="helpDropdown"
                    btnTitle=""
                  />
                </NewTooltip>
              </Box>
            )}
            <DropdownContainer>
              <CalendarDropdown
                text={calendarDropdownText}
                events={events}
                now={isoDate}
                redirectToCalendar={redirecToCalendar}
                courseColors={courseColors}
                loading={loadingEvents}
              />
            </DropdownContainer>
            {logged && hasPermissions && (
              <NewTooltip label={t('CrossMyProfile')} isDisabled={isOpen}>
                <NewDropdown
                  displayDropdown={displayDropdown}
                  dropdownType="avatar"
                  userInfo={user}
                />
              </NewTooltip>
            )}
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

const LogoLink = ({ config }: HeaderProps) => {
  if (config.labelRedirect && config.urlRedirect) {
    return (
      <a href={config.urlRedirect}>
        <span className="label-logo">{config.labelRedirect}</span>
      </a>
    )
  } else if (config.showHeaderLogo) {
    return (
      <Link to="/courses" data-testid="logo-redirect">
        <LogoEclass />
      </Link>
    )
  }

  return null
}

export default Header
