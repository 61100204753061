import { Box, useMediaQuery } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'
import { useEffect, useRef, useState } from 'react'

export const DropdownContainer = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [marginLeft, setMarginLeft] = useState(0)
  const [w, setW] = useState('0')
  const [isMobile] = useMediaQuery('(max-width: 576px)')

  const handlePosition = () => {
    if (containerRef.current) {
      const menuList = containerRef.current.querySelector('.chakra-menu__menu-list') as HTMLElement
      if (menuList) {
        const parent = menuList.parentElement
        if (parent) {
          const rect = parent.getBoundingClientRect()
          if (rect.left > 0) {
            setMarginLeft(rect.left)
          }
          if (rect.width === 0) {
            setW('100vw')
          }
        }
      }
    }
  }

  useEffect(() => {
    const observerCallback: MutationCallback = (mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
          const target = mutation.target as HTMLElement
          if (target.getAttribute('aria-expanded') === 'true') {
            handlePosition()
          }
        }
      })
    }

    const menuButton = document.querySelector('.chakra-menu__menu-button')
    if (menuButton) {
      const observer = new MutationObserver(observerCallback)
      observer.observe(menuButton, { attributes: true })

      return () => observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (isMobile) {
        handlePosition()
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobile])

  return (
    <Box
      ref={containerRef}
      className="DropdownContainer"
      sx={{
        '@media screen and (max-width: 576px)': {
          '& > :first-child': {
            '&:after': {
              display: 'none!important',
            },
            '& > div': {
              position: 'absolute!important',
              zIndex: 5,
              mt: '-16px!important',
              '.chakra-menu__menu-list': {
                maxH: 'max-content',
                ml: isMobile ? `-${marginLeft}px!important` : 0,
                overflow: 'visible',
                top: '-10!important',
                w: `${w}!important`,
              },
              // TODO: esto debiese de ir en el componente de ui-kit pero en razon del timpo lo agregue directon en la v8
              header: {
                w: '100%',
                '&:after': {
                  borderColor: `transparent transparent ${
                    vars('colors-neutral-davysGrey') ?? '#5C5C5C'
                  } transparent`,
                  borderStyle: 'solid',
                  borderWidth: '0 11px 10px 11px',
                  content: "''",
                  h: '0',
                  left: `${marginLeft + 5}px`,
                  position: 'absolute',
                  top: '-9px',
                  w: '0',
                },
              },
            },
          },
        },
      }}
    >
      {children}
    </Box>
  )
}
