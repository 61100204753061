import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

import { Breadcrumb as UIBreadcrumb } from './Breadcrumb-uikit'
import { useBreadcrumb } from './useBreadcrumb'
import './style.css'
import { Container } from 'app/Layouts'

export const BreadCrumb = ({
  className,
  fromPortal,
}: {
  className?: string
  fromPortal?: boolean
}) => {
  const { links } = useBreadcrumb({ fromPortal })

  if (!links || links.length === 0) return <></>

  return (
    <Box
      bg={vars('colors-neutral-cultured2')}
      id="BreadCrumb"
      pb="16px"
      pt="16px"
      className={`BreadCrumb ${className}`}
      sx={{
        'ol li:first-child': {
          flexShrink: 1,
        },
        'ol li:last-child': {
          flexShrink: 3,
          minW: '90px',
        },
      }}
    >
      <Container size="xl">
        <UIBreadcrumb links={links} />
      </Container>
    </Box>
  )
}
