import { Box } from '@chakra-ui/react'
import { BtnPrimary, vars } from '@eclass/ui-kit'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ShowMore as Style } from './style'
import { Types } from './types'

export const ShowMoreButton = ({
  totalPages,
  limit,
  page,
  loading,
  totalComments,
  moreResults,
}: Types.ShowMore) => {
  const { t } = useTranslation()
  const count = totalPages === 1 ? totalComments : limit
  const [visibleComments, setVisibleComments] = useState(count)

  /** Voy calculando el número de comentarios visibles y el total de estos */
  useEffect(() => {
    if (!loading) {
      // si estoy en la última pagina el total que se muestra es igual al total de comentarios
      if (page + 1 === totalPages) {
        setVisibleComments(totalComments)
      } else {
        setVisibleComments((page + 1) * limit)
      }
    }
  }, [loading])

  if (totalComments <= limit || visibleComments === totalComments) {
    return (
      <Style.Wrapper role="ShowMoreButton">
        <CountComment count={visibleComments} total={totalComments} />
      </Style.Wrapper>
    )
  }

  const hasRemaining = totalPages - (page + 1) === 1

  return (
    <Style.Wrapper role="ShowMoreButton">
      <Box display="flex" flexDirection="column" gap="8px">
        <BtnPrimary isLoading={loading} onClick={moreResults}>
          {t('ResourceViewMore', {
            count: hasRemaining ? totalComments - visibleComments : limit,
          })}
        </BtnPrimary>
        <CountComment count={visibleComments} total={totalComments} />
      </Box>
    </Style.Wrapper>
  )
}

export const CountComment = ({ count, total }: { count: number; total: number }) => {
  const { t } = useTranslation()

  return (
    <Box
      as="span"
      fontWeight={400}
      fontSize="14px"
      lineHeight="19px"
      color={vars('colors-neutral-gray')}
    >
      {t('ResourceCommentCount', { count, total })}
    </Box>
  )
}
