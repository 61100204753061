import { useSelector, useDispatch } from 'react-redux'

import * as ReduxTypes from 'app/Redux/types'
import { apiURL } from 'Config/constants'
import { IEvent } from '..'

/**
 * Hook para obtener eventos desde la API.
 *
 * ## Ejemplo de uso:
 * ```tsx
 * const { getEvents } = useRequests()
 *
 * const fetchEvents = async () => {
 *   try {
 *     const events = await getEvents({ from: startDate, to: endDate })
 *     console.log('Eventos cargados:', events)
 *   } catch (error) {
 *     console.error('Error al cargar eventos:', error)
 *   }
 * }
 * ```
 */
export const useRequests = () => {
  const dispatch = useDispatch()
  const { loggedUser } = useSelector((state: ReduxTypes.Store) => state)

  const getEvents = async (params: { studentId?: string; from: string; to?: string }) => {
    if (!loggedUser.token) {
      throw new Error('Usuario no autenticado')
    }

    try {
      const token = await getToken({
        token: loggedUser.token,
        personId: `${loggedUser?.user?.id}`,
        ...params,
      })

      if (!token) {
        throw new Error('No se recibió un token válido')
      }

      const newEvents = await getData(token)

      if (newEvents.length > 0) {
        dispatch({
          type: 'CALENDAR_ADD_COURSE_COLORS',
          payload: newEvents,
        })
      }
      return newEvents
    } catch (error) {
      console.error('Error en getEvents:', error)
      throw error // Lanzamos el error para manejarlo donde se llama getEvents
    }
  }

  return { getEvents }
}

const getToken = async ({
  token,
  studentId,
  personId,
  from,
  to,
}: {
  token: string
  personId: string
  studentId?: string
  from: string
  to?: string
}): Promise<string> => {
  const idParam = studentId ? `student_id=${studentId}` : `person_id=${personId}` // 14469968 id pruebas
  const url = `${apiURL}/events/get-token?${idParam}&from=${from}&to=${to}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
  if (!response.ok) throw new Error('Error al obtener el token')

  const data = await response.json()
  return data.data.token
}

const getData = async (token: string): Promise<IEvent[]> => {
  const response = await fetch(`${apiURL}/events?token=${token}`, {
    method: 'GET',
    headers: { Accept: 'application/json' },
  })

  if (!response.ok) throw new Error('Error al obtener los datos')

  const data = await response.json()
  return data.data.events
}
