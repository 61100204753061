// @Dependencies
import axios from 'axios'
import { store } from '../../Redux'
import jwtDecode from 'jwt-decode'
import { LoginToken } from './types'
/**
 * Devuelve el token con el que está logueado el usuario y lo setea en las cabeceras de Axios
 *
 * Además valida que el token no esté expirado
 * @example
 *   import { getToken } from '../../../Services'
 *   const decoded = getToken()
 */
export const getToken = ({ testId }: { testId?: number } = {}): LoginToken | null => {
  let token = store.getState().loggedUser.token
  const href = window.location.href
  axios.defaults.headers.common['app-referer'] = href
  let expired = false
  let hasTokenTest = false

  /**
   * En esta sección se consulta el token del test de nivel que está guardado en el localstorage, si este viene se agrega a los headers de axios si no se agrega el del usuario logueado.
   */
  const tokenLvlTest = window.localStorage.getItem('token_test')
  if (tokenLvlTest) {
    token = tokenLvlTest
    hasTokenTest = true
  }

  if (token) {
    const tokenDecoded: LoginToken = jwtDecode(token)
    const currentTime = new Date().getTime() / 1000
    if (currentTime < tokenDecoded.exp) {
      axios.defaults.headers.common.Authorization = `bearer ${token}`
      return tokenDecoded
    } else {
      expired = true
    }
  } else {
    expired = true
  }

  if (expired) {
    window.sessionStorage.setItem('session', 'expired')
    window.sessionStorage.setItem('lastURL', window.location.pathname)
    if (window.localStorage) {
      window.localStorage.removeItem('persist:platform')
      window.localStorage.removeItem('platform:apollo:token')
    }
    const logoutPath = window.location.pathname.includes('/test-level/')
      ? ['/test-level', testId].join('/')
      : '/logout/inactive'

    if (hasTokenTest) {
      window.localStorage.removeItem('token_test')
      window.location.assign(logoutPath)
      return null
    }
    window.location.assign(logoutPath)
  }
  return null
}

export default getToken
