import { useEffect, useState } from 'react'
import { DropdownItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

import { Pressed, DelayLink } from 'app/Components/UI'
import {
  CoursesIcon,
  AcademicHistorialIcon,
  ProfileIcon,
  CalendarIcon,
} from 'app/Components/UI/Icons'
import * as ItemStyle from './ItemStyle'
import { HiddenBackground } from './HiddenBackground'
import { useShowProfile } from 'app/Services'
import { useImpersonate } from 'app/Components/Impersonate'

const MenuItemAvatar = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  const [isOpen, setIsOpen] = useState(false)
  const { showProfile } = useShowProfile()
  const { impersonate } = useImpersonate()

  useEffect(() => {
    const openDrop = document.getElementsByClassName('openDrop')
    setIsOpen(openDrop.length === 1 && isMobile)
  }, [])

  const onLogout = () => {
    history.push('/logout/inactive')
  }

  return (
    <>
      {isOpen && <HiddenBackground />}
      <Item
        to="/courses"
        id="CrossMyActiveCourses"
        icon={<CoursesIcon />}
        text={t('CrossMyActiveCourses')}
      />

      <Item
        to="/calendar"
        id="CrossMyACalendar"
        icon={<CalendarIcon />}
        text={t('CalendarMyCalendar')}
      />

      {showProfile ? (
        <>
          <Item
            to="/courses-past"
            id="CrossAcademicHistory"
            icon={<AcademicHistorialIcon />}
            text={t('AcademicHistory')}
          />
          {!impersonate && (
            <Item
              to="/profile"
              id="CrossMyProfile"
              icon={<ProfileIcon />}
              text={t('CrossMyProfile')}
            />
          )}

          <ItemStyle.AvatarLogout>
            <DropdownItem className="logout" onClick={onLogout}>
              <Pressed dark>
                <ItemStyle.Logout>
                  <span>{t('CrossEndSession')}</span>
                </ItemStyle.Logout>
              </Pressed>
            </DropdownItem>
          </ItemStyle.AvatarLogout>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

const Item = ({
  to,
  id,
  icon,
  text,
}: {
  to: string
  id: string
  icon: JSX.Element
  text: string
}) => (
  <DropdownItem>
    <Pressed dark>
      <DelayLink to={to}>
        <Box
          id={id}
          textTransform={'uppercase'}
          color={vars('colors-neutral-spanishGrey')}
          fontWeight={500}
          fontSize="12px"
          sx={{
            svg: {
              mr: '10px',
            },
          }}
        >
          {icon}
          {text}
        </Box>
      </DelayLink>
    </Pressed>
  </DropdownItem>
)

export default MenuItemAvatar
