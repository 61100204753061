import { CalendarEventExternal } from '@schedule-x/calendar'
import { isBefore, startOfDay, parseISO, isAfter } from 'date-fns'

import { useDateFormat } from 'app/Services'
import { useEventsTimeZone } from 'app/Components/Calendar/Dropdown/useFormatedEvents'

export interface ICalendarEventExternalAdapted extends CalendarEventExternal {
  isPast: boolean
  calendarId: string
}

export interface IListCourses {
  color: string
  courseId: string
  course: string
}

interface IUseEvents {
  now: Date
}

export const useFormatEventsForCalendar = ({ now }: IUseEvents) => {
  const { dateFormat } = useDateFormat()
  const { formatDateTZ } = useEventsTimeZone()

  const today = startOfDay(parseISO(formatDateTZ(now)))

  const format = (date: Date) =>
    dateFormat({
      date: date,
      format: {
        free: `u-MM-dd HH:mm`,
      },
    })

  const formatted = (newEvents) => {
    if (!newEvents || newEvents.length === 0) {
      return []
    }

    const filterEvents = filterEventsNoInitCourse(newEvents, now)

    return filterEvents.map((event) => {
      const startDate = parseISO(event.start)
      return {
        id: String(event.id),
        title: event.associated_resource.name,
        start: format(startDate),
        end: format(startDate),
        dateStart: event.start,
        endReal: event.end,
        calendarId: String(event.course.id),
        course: event.course.name,
        isPast: isBefore(startOfDay(startDate), today),
      }
    })
  }

  const setUniqueCalendarIds = (newEvents) =>
    Array.from(new Set(newEvents.map((event) => String(event.calendarId))))

  const setListCourses = (newEvents) =>
    Array.from(
      new Set(
        newEvents.map((event) =>
          JSON.stringify({ course: event.course, courseId: event.calendarId })
        )
      )
    ).map((jsonString) => JSON.parse(jsonString as string))

  return {
    formatted,
    setUniqueCalendarIds,
    setListCourses,
  }
}

export const filterEventsNoInitCourse = (events, now) => {
  const today = startOfDay(now instanceof Date ? now : new Date())
  const courseStart = (type: string) => ['init-course', 'init-course-flexible'].includes(type)

  const coursesIds = new Set(
    events
      .filter((event) => {
        const startDate = event.start ? parseISO(event.start) : null // Verificación extra
        return courseStart(event.type) && startDate && isAfter(startOfDay(startDate), today)
      })
      .map((event) => event.course_id)
  )

  return events.filter((event) => !coursesIds.has(event.course_id) || courseStart(event.type))
}
