import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'

import { Icon } from 'app/Components/UI/Icons/Icon'

import { HeaderAvatar as Style } from './style'
import { MyAvatar } from './MyAvatar'

import { Props } from './types'

/**
 * Componente que muestra el avatar y nombre del usuario,
 * también muestra si lo quieres si eres ayudante
 * @example <Header showHelper={false} user={user} />
 */
export const HeaderAvatar = ({
  user,
  selfComment,
  profileId,
  suggestedAnswer,
  size,
}: Props.HeaderAvatar) => {
  const { t } = useTranslation()

  const name =
    user?.fullName && user.fullName.trim() !== '' ? user.fullName : t('CommentSystemAnonymous')

  const getIcon = (id: number) => {
    switch (id) {
      case 6:
        return (
          <Style.Helper>
            <Icon name="coordinator" />
            {t('QuestionCoordinator')}
          </Style.Helper>
        )
      case 3:
        return (
          <Style.Helper>
            <Icon name="assitant" />
            {t('QuestionHelper')}
          </Style.Helper>
        )
      case 2:
        return (
          <Style.Helper>
            <Icon name="board" />
            {t('QuestionTeacher')}
          </Style.Helper>
        )
      default:
    }
  }

  return (
    <Style.Wrapper
      role="HeaderAvatar"
      className={`${suggestedAnswer ? 'suggestedAnswer' : ''} headerAvatar`}
    >
      <MyAvatar user={user} size={size} />
      <Style.Info>
        {!selfComment ? (
          <div className="name">{name}</div>
        ) : (
          <Box className="selfComment" display="flex" flexDir="column">
            <span className="by">{name}</span> <span className="date">{selfComment.date}</span>
          </Box>
        )}
        {getIcon(profileId!)}
      </Style.Info>
    </Style.Wrapper>
  )
}
