import styled, { createGlobalStyle } from 'styled-components/macro'
import { vars } from '@eclass/ui-kit'

import { UIVars } from 'app/Components/UI'
import { desktop, mobile } from './Answers/style'

/** Wrapper para botón tipo link */
export const TextLink = styled.span`
  color: ${UIVars.setColors.primary};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: underline;
  :hover {
    color: ${UIVars.setColors.black};
    text-decoration: underline;
  }
`

/** Wrapper para comentarios */
export const ListComment = styled.section`
  & > article,
  & > section {
    &:not(:first-child) {
      // margin-top: 25px;
    }
  }
`

export const Margin = {
  Editor: styled.div`
    margin-bottom: 25px;
  `,
  Answer: styled.div`
    .Loading {
      opacity: 0.2;
    }
  `,
}

/** estilo para respuestas */
export const WrapperAnswers = styled.div`
  &.withPadding {
    margin-top: 1.5rem;
    padding-left: ${desktop};

    @media screen and (max-width: 640px) {
      padding-left: ${mobile};
    }
  }
`
/** estilo para botón cargar más */
export const ShowMore = {
  Wrapper: styled.article`
    margin-bottom: 36px;

    padding-bottom: 0;
    padding-top: 33px !important;
    text-align: center;

    .Gone {
      display: none;
    }

    button {
      width: 231px;
      height: 58px;

      div {
        div {
          div {
            .button__container {
              width: 231px;
              height: 58px;
            }
          }
        }
      }
    }
  `,
}

/**
 * estilos css para el componente HeaderAvatar
 * @see src/app/Components/ComponentSystem/HeaderAvatar
 */
export const HeaderAvatar = {
  Wrapper: styled.div`
    align-items: center;
    display: flex;
    width: 100%;

    &.suggestedAnswer {
      > div {
        > figure {
          background-color: ${vars('colors-icon-lightSeaGreen')}!important;
        }
      }
    }
  `,
  Info: styled.div`
    color: ${UIVars.setColors.black};
    margin-left: 16px;
    > .name {
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }
    .selfComment {
      font-size: 12px;
      display: flex;
      .by {
        font-weight: 700;
        font-size: 14px;
        lineheight: 19px;
      }
      .date {
        color: ${vars('colors-neutral-gray')};
        font-size: 12px;
        font-weight: 400;
      }
      div:nth-child(2) {
        display: inline-flex;
        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;
          display: flex;
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              margin-left: 10px;
              margin-right: 8px;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              margin-top: 2px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 434px) {
      .by {
        padding-right: 0px;
        border-right: 0px !important;
      }
      .selfComment {
        flex-direction: column;
        div:nth-child(2) {
          ul {
            li {
              svg {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  `,
  Helper: styled.div`
    align-items: center;
    display: flex;
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    > svg {
      margin-right: 8px;
    }
  `,
}
export const Avatar = {
  MyAvatar: styled.div`
    padding: 0px;
    > figure > span {
      font-size: 16px !important;
    }
    &.itsMe {
      & > figure {
        border: solid 3px ${UIVars.setColors.primary};
      }
    }
    &.small {
      & > figure {
        border-width: 1.5px;
        height: 25px;
        width: 25px;
        span {
          font-size: 13px;
        }
      }
    }
  `,
}

export const HeaderComment = {
  Header: styled.div`
    padding-bottom: 40px;
    .Title {
      display: flex;
      justify-content: center;
      header {
        .container-fluid {
          div {
            div {
              min-height: unset;
            }
          }
        }
      }
      header,
      h2 {
        width: unset;
      }
      h2 {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 24px;
      }
    }
    p {
      text-align: center;
      color: ${UIVars.setColors.brownishGrey};
    }
  `,
}
type Props = {
  type?: string
}
export const Alert = {
  Wrapper: createGlobalStyle<Props>`
    .modal-comment{
      .modal-content{
        border-radius: 10px !important;
        box-shadow: 0 5px 10px 0 rgba(199, 199, 199, 0.5);
      }
      .close-modal{
        display: none;
      }
      .modal-body{
        padding: 0 15px;
        div{
        p{
          font-size: 16px;
        }
        }
      }
      .modal-footer{
        background-color: ${UIVars.setColors.whiteThree};
        border-top: ${UIVars.borderSolid};
        padding: 0;
        button,
        .button__container {
          border-radius: 0;
          border: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
          width: ${(props) => (props.type !== 'size' ? '50%' : '100%')};
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100%;
          justify-content: center;
            :not(:first-child){
            border-left: ${UIVars.borderSolid};
          }
        }
        .button__container {
          display: flex;
          height: 57px;
          width: 100%
        }
      }
      @media screen and (max-width: 768px) {
        padding: 20px 20px;
      }
    }
  `,
  Message: styled.div`
    color: ${UIVars.setColors.black};
    padding: 25px 15px 0;
    text-align: center;
    h4 {
      font-size: 20px;
      font-weight: bold;
      line-height: 23px;
      margin-top: 16px;
    }
    p {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 24px !important;
      margin-top: 8px;
    }
  `,
  Check: styled.div`
    color: ${UIVars.setColors.blackFour};
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    line-height: 16px;
    margin-bottom: 16px;
    text-align: center;
    label {
      align-items: center;
      display: flex;
    }
    input {
      margin-top: 0;
    }
  `,
}
