// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { withTranslation } from 'react-i18next'

// @Dependencies
import BaseForm from './BaseForm'
import vars from '../variables'
import { accordionClosed } from '../../../../assets/svg'

// @Component
export class Select extends Component {
  state = {
    currentValue: this.props.select || 0,
    placeholder: '',
    elementWidth: 0,
  }

  componentDidMount() {
    const select = document.querySelector(`#${this.props.name}`)
    if (select) {
      this.setState({ elementWidth: select.offsetWidth })
    }
  }

  static getDerivedStateFromProps(nextsProps) {
    let placeholder = nextsProps.t('LogInSelect')
    if (nextsProps.placeholder) {
      placeholder = nextsProps.placeholder
    }

    return {
      placeholder,
      currentValue: nextsProps.select,
    }
  }

  handleChange = (e) => {
    this.setState({ currentValue: e.target.value })
    if (this.props.onChange !== undefined) {
      this.props.onChange(e)
    }
  }

  setValue = (option, key) => {
    return option.id || key
  }

  setText = (option) => {
    if (option.nombre || option.name) {
      return option.nombre || option.name
    } else {
      return option
    }
  }

  render() {
    const {
      disabled,
      icon,
      info,
      inputSize,
      label,
      loading,
      name,
      options,
      required,
      size,
      state,
      tooltip,
      t,
      className,
      placeholderEmpty,
    } = this.props
    const { currentValue, placeholder, elementWidth } = this.state

    return (
      <StyledSelect className="Select" elementWidth={elementWidth}>
        <BaseForm
          icon={icon}
          info={info}
          inputSize={inputSize}
          label={label}
          loading={loading || (options.length === 0 && currentValue)}
          name={name}
          required={required}
          tooltip={tooltip}
        >
          <select
            className={`form-control input-${size} ${className}`}
            disabled={disabled}
            id={name}
            name={name}
            onChange={this.handleChange}
            required={required}
            state={state}
            value={currentValue}
          >
            {/* cuando no trae opciones y trae valor ó cuando esta cargando */}
            {((options.length === 0 && currentValue) || loading) && (
              <option value="">{t('Loading')}</option>
            )}
            {/* cuando no trae valor por defecto y no esta cargando */}
            {!currentValue && !loading && (
              <option value="">{placeholderEmpty ? '' : placeholder}</option>
            )}

            {options.length &&
              options.map((option, key) => {
                return (
                  <option key={key} name={`data[${name}]`} value={this.setValue(option, key)}>
                    {this.setText(option)}
                  </option>
                )
              })}
          </select>
        </BaseForm>
      </StyledSelect>
    )
  }
}

// @Proptypes
Select.propTypes = {
  defaultOption: PropTypes.string,
  disabled: PropTypes.bool,
  info: PropTypes.node,
  icon: PropTypes.string,
  inputSize: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  size: PropTypes.string,
  state: PropTypes.string,
  tooltip: PropTypes.string,
  select: PropTypes.node,
  className: PropTypes.string,
  placeholderEmpty: PropTypes.bool,
}

Select.defaultProps = {
  placeholderEmpty: false,
  size: 'md',
  className: '',
}

// @Export Component
export default withTranslation()(Select)

// @Styles
const StyledSelect = styled.div`
  select {
    line-height: ${vars.lineHeight};
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${accordionClosed});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: ${(props) => props.elementWidth - 30}px;
    @media screen and (max-width: 425px) {
      background-position-x: ${(props) => props.elementWidth - 45}px;
    }
  }
`
